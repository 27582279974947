import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//G-DW0NSZ1CGW

const ScrollToTop = lazy(() => import('./helpers/ScrollToTop'));
const NSMSticky = lazy(() => import('./NSMSticky'));

const Home = lazy(() => import("./pages/Home"))
const Service = lazy(() => import("./pages/Service"))
const SingleService = lazy(() => import("./pages/SingleService"))
const SingleBlog = lazy(() => import("./pages/SingleBlog"))
const Blog = lazy(() => import("./pages/Blog"))
const Gallery = lazy(() => import("./pages/Gallery"))
const About = lazy(() => import("./pages/About"))
const Contact = lazy(() => import("./pages/Contact"));
const NotFound = lazy(() => import("./pages/NotFound"))

const App = () => (
  <Suspense fallback={<div>...</div>}>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/services'}`}
            component={Service}
          />
          <Route path={`${process.env.PUBLIC_URL + '/services/:serviceID'}`}
            component={SingleService}
          />

          <Route path={`${process.env.PUBLIC_URL + '/blog/:blogID'}`}
            component={SingleBlog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={Blog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            component={Gallery}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route exact component={NotFound} />
        </Switch>
        <NSMSticky />
      </ScrollToTop>
    </Router>
  </Suspense>
);

export default App;
